import each from 'lodash/each';
import replace from 'lodash/replace';

export const ROUTES = {
    login: process.env.REACT_APP_API_BASE_PATH+'/api/Auth/SignIn',
    changePassword: process.env.REACT_APP_API_BASE_PATH+'/api/Auth/ChangePassword',
    registerUser: process.env.REACT_APP_API_BASE_PATH + '/register',
    userProfile: process.env.REACT_APP_API_BASE_PATH + '/register/<userId>',

    getsimpleList: process.env.REACT_APP_API_BASE_PATH + '/api/SimpleList',

    getAllData: process.env.REACT_APP_API_BASE_PATH + '/api/<type>',
    getAllDataByParms: process.env.REACT_APP_API_BASE_PATH + '/api/<type>/ByParams',
    getRowByParams: process.env.REACT_APP_API_BASE_PATH + '/api/<type>/RowByParams',
    getDataWithId: process.env.REACT_APP_API_BASE_PATH + '/api/<type>/<id>',
    saveDataWithId: process.env.REACT_APP_API_BASE_PATH + '/api/<type>/update/<id>',

    loginDocEra: process.env.REACT_APP_DOC_API_URL+'/api/Authentication/Authorize?username=' + process.env.REACT_APP_DOC_API_USERNAME + '&Password=' + process.env.REACT_APP_DOC_API_PASSWORD  + '&RemoteUser=<user>',
    saveDocs: process.env.REACT_APP_DOC_API_URL + '/api/doc/<method>?objectId=<objectId>'
};

/**
 * getRoute creates the URL through provided routeName & params arguments
 * @param  routeName   any object name of ROUTES_OBJ e.g. login
 * @param  [params={}] param values replace with strings present <...>.
 * @return URL
 */

const getRoute = (routeName, params = {}) => {
    let url = ROUTES[routeName];
    each(params, (val, key) => {
        val = Array.isArray(val) ? val.join(',') : val;
        url = replace(url, new RegExp(`<${key}>`, 'g'), val);
    });
    return url;
};

export default getRoute;
