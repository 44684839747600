import * as React from "react";
import { formatDate } from '@telerik/kendo-intl';
import { DatePicker } from "@progress/kendo-react-dateinputs";

export const KendoDateTimeFormat = (props) => {
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? new Date() : dataItem[field];

    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }); 
        }
    };

    return (
        <td>
            {dataItem.inEdit ? (
                <DatePicker
                    value={new Date(dataValue)}
                    onChange={handleChange}
                />
            ) : (
                <div style = {{textAlign: 'center' }}>
                    {
                        formatDate(new Date(dataValue), "d")
                    }
                </div>
            )}
        </td>
    );
};