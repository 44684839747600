import axios from 'axios';
import jwt_decode from 'jwt-decode'
import getRoute from '../Api/routes';
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";

export const setAuthToken = token => {
    if (token !== 'undefined' && token != null) {
        const decodedtoken = jwt_decode(token);
        console.log("decode: " + JSON.stringify(decodedtoken));
        localStorage.setItem("token", token);
        localStorage.setItem("userId", decodedtoken.sub);
        localStorage.setItem("Name", decodedtoken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']);
        localStorage.setItem("Email", decodedtoken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailAddress']);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
}

export const signOut = () => {
    localStorage.clear();
    delete axios.defaults.headers.common["Authorization"];
    window.location.href = "/login";
}

const useAuth = () => {
    const token = localStorage.getItem("token");
    if (token != 'undefined' && token != null) {
        const decodedtoken = jwt_decode(token);
        let expired = decodedtoken.exp < (Date.now() - 1000 * 60 * 5) / 1000;
        if (expired) {
            localStorage.clear();
            delete axios.defaults.headers.common["Authorization"];
            return false;
        } else {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return true;
    }
    return false;
};

export const ProtectedRoutes = () => {
    const location = useLocation();
    const isAuth = useAuth();
    if (isAuth) {
        return <Outlet />
    } else {
        //return Navigate("/login", { replace: true });
        return <Navigate to="/login" replace={true} state={{ from: location }} />
    }
};

const failedResponse = (error) => {
    return Promise.reject(error);
};

export const getRequest = (route) => {
    if (!route) {
        return;
    }
    return axios({
        method: 'GET',
        url: route,
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            Sentry.captureException("getRequest Exception=" + (error))
            return failedResponse(error);
        });
};

export const postRequest = (route, data) => {
    return axios({
        method: 'POST',
        url: route,
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            Sentry.captureException("postRequest Exception=" + (error))
            return failedResponse(error);
        });
};

export const putRequest = (route, data) => {
    return axios({
        method: 'PUT',
        url: route,
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            Sentry.captureException("putRequest Exception=" + (error))
            return failedResponse(error);
        });
};

export const postRequestDocs = (route, data) => {
    return axios({
        method: 'POST',
        url: route,
        data: data,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return failedResponse(error);
        });
};

export const getSimpleList = () => {
    var data = JSON.stringify({
        "Option": "CompanyType"
    });
    const route = getRoute('getsimpleList');
    return postRequest(route, data);
};
