import getRoute from '../routes';
import { postRequest, putRequest, getRequest, postRequestDocs } from '../index';

export const getItem = (
    type,
    id,
    rNm
) => {
    const route = getRoute(rNm, { type, id });
    return getRequest(route)
};

export const getItems = (
    type,
    reqparms,
    rNm
) => {
    const route = getRoute(rNm, { type });
    return postRequest(route, reqparms)
};

export const saveItem = (
    type,
    id,
    data,
    rNm
) => {
    const route = getRoute(rNm, { type, id });
    return putRequest(route, data)
};

export const getItemNoParam = (
    type,
    rNm
) => {
    const route = getRoute(rNm, { type });
    return getRequest(route)
};

export const uploadDocs = (
    method,
    data,
    rNm,
    objectId
) => {
    const route = getRoute(rNm, { method, objectId });
    return postRequestDocs(route, data)
};