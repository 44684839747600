import getRoute from '../routes';
import { postRequest, getRequest } from '../index';

export const registerUser = (
    userName,
    password
) => {
    var data = JSON.stringify({
        "UserName": userName,
        "Password": password
    });
    const route = getRoute('registerUser');
    return postRequest(route, data);
};

export const authenticateUser = (
    userName,
    password,
    rememberMe
) => {
    var data = JSON.stringify({
        "UserName": userName,
        "Password": password,
        "rememberMe": rememberMe
    });
    const route = getRoute('login');
    return postRequest(route, data);
};

export const getUserInfo = (userId) => {
    const route = getRoute('userProfile', { userId });
    return getRequest(route);
};

export const changeUserPassword = (
    data
) => {
    const route = getRoute('changePassword');
    return postRequest(route, data);
};


