import { React, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button } from '@progress/kendo-react-buttons';
import {  Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput } from '../../Components/form-components';
import { passwordValidator } from '../../Components/validators';

import { changeUserPassword } from "../../Api/handlers/Account";

const Index = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const errRef = useRef();
    const succRef = useRef();

    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');

    const [pwdCurrent, setPwdCurrent] = useState('');
    const [validPwdCurrent, setValidPwdCurrent] = useState(false);

    const [pwdNew, setPwdNew] = useState('');
    const [validPwdNew, setValidPwdNew] = useState(false);

    const [pwdConfirm, setPwdConfirm] = useState('');
    const [validPwdConfirm, setValidPwdConfirm] = useState(false);

    const [navigateTo, setNavigateTo] = useState('/');

    const formFields = { currentPassword: "", newPassword: "", confirmNewPassword: "" }

    useEffect(() => {
        setValidPwdCurrent(pwdCurrent);
    }, [pwdCurrent])

    useEffect(() => {
        setValidPwdNew(pwdNew);
    }, [pwdNew])

    useEffect(() => {
        setValidPwdConfirm(pwdConfirm);
    }, [pwdConfirm])

    useEffect(() => {
        if (pwdNew != '' && pwdConfirm != '') {
            if (pwdNew != pwdConfirm)
                setErrMsg('Error: New and Confirm Password should match');
            else
                setErrMsg('');
        }
    }, [pwdNew, pwdConfirm])

    useEffect(() => {
        if (location.state != null)
            setNavigateTo(location.state.from);
    }, [location.state])

    const setData = dataItem => {
        const changePasswordTemp =
        {
            currentPassword: dataItem.currentPassword,
            newPassword: dataItem.newPassword,
            confirmNewPassword: dataItem.confirmNewPassword
        }

        if (errMsg === '') {

            changeUserPassword(JSON.stringify(changePasswordTemp))
                .then(function (response) {
                    setPwdCurrent('');
                    setPwdNew('');
                    setPwdConfirm('');
                    setSuccMsg('Password Changed Successfully');
                })
                .catch(function (err) {
                    if (!err?.response) {
                        setErrMsg('No Server Response ' + err); setSuccMsg('');
                    } else {
                        setErrMsg('Current Password is invalid'); setSuccMsg('');
                    }
                    errRef.current.focus();
                });
        }
    }

    return (
        <>
            <Form
                onSubmit={setData} initialValues={formFields}
                key={JSON.stringify(formFields)}
                render={(formRenderProps) => (
                    <FormElement
                        style={{
                            width: 800,
                            margin: 20,
                            marginTop: 20
                        }}
                    >

                        <fieldset className={"k-form-fieldset"}>
                            <legend className={"k-form-legend"}>
                                Change Password
                            </legend>

                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            <p ref={succRef} className={succMsg ? "succmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    id={"currentPassword"}
                                    name={"currentPassword"}
                                    //label={"Current Password"}
                                    component={FormInput}
                                    type={"password"}
                                    onChange={(e) => setPwdCurrent(e.target.value)}
                                    required={true} 
                                    placeholder="Current Password"
                                    aria-invalid={validPwdCurrent ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    className="loginInput"
                                ></Field>
                            </div>
                            <div>
                                <Field
                                    id={"newPassword"}
                                    name={"newPassword"}
                                    //label={"New Password"}
                                    component={FormInput}
                                    type={"password"}
                                    onChange={(e) => setPwdNew(e.target.value)}
                                    value={pwdNew}
                                    validator={passwordValidator}
                                    required
                                    placeholder="New Password"
                                    aria-invalid={validPwdNew ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    className="loginInput"
                                />
                            </div>
                              <div>
                                <Field
                                    id={"confirmNewPassword"}
                                    name={"confirmNewPassword"}
                                    //label={"Confirm Password"}
                                    component={FormInput}
                                    type={"password"}                                    
                                    onChange={(e) => setPwdConfirm(e.target.value)}
                                    validator={passwordValidator}
                                    value={pwdConfirm}
                                    required
                                    placeholder="Confirm Password"
                                    //aria-invalid={validPwdConfirm ? "false" : "true"}
                                    //aria-describedby="pwdnote"
                                    className="loginInput"
                                />

                            </div>

                            <div className="k-form-buttons">
                                <Button
                                    themeColor={"primary"}
                                    type={"submit"}
                                    disabled={!formRenderProps.allowSubmit}                                >
                                    Submit
                                </Button>
                            </div>
                        </fieldset>
                    </FormElement>
                )}
            />
        </>
    )
}

export default Index;