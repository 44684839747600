/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-computed-key */
import * as React from 'react';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, Navigate } from 'react-router-dom';
import { signOut, ProtectedRoutes } from "../../Api/index";


const CustomItem = props => {
    const {
        visible,
        ...others
    } = props;
    const arrowDir = props['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
    return <React.Fragment>
        {props.visible === false ? null : <DrawerItem {...others}>
            <span className={'k-icon ' + props.icon} />
            <span className={'k-item-text'}>{props.text}</span>
            {props['data-expanded'] !== undefined && <span className={"k-icon " + arrowDir} style={{
                position: "absolute",
                right: 10
            }} />}
        </DrawerItem>}
    </React.Fragment>;
};

export const DrawerContainer = props => {
    const [drawerExpanded, setDrawerExpanded] = React.useState(true);
    const navigate = useNavigate();


    const [items, setItems] = React.useState([
        {
            text: 'Home',
            icon: 'k-i-home',
            id: 1,
            route: '/'
        }, {
            separator: true
        }, {
            text: 'Service Provider Investigation',
            icon: 'k-i-folder',
            className: 'menugroup',
            id: 2,
            route: '/caterTeamInvestigation/all'
        }
    ]);

    const handleClick = () => {
        setDrawerExpanded(!drawerExpanded);
    };

    const onSelect = ev => {
        const currentItem = ev.itemTarget.props;
        const isParent = currentItem['data-expanded'] !== undefined;
        const nextExpanded = !currentItem['data-expanded'];
        const newData = items.map(item => {
            const {
                selected,
                ['data-expanded']: currentExpanded,
                id,
                ...others
            } = item;
            const isCurrentItem = currentItem.id === id;
            return {
                selected: isCurrentItem,
                ['data-expanded']: isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others
            };
        });
        navigate(ev.itemTarget.props.route);
        setItems(newData);
    };

    const data = items.map(item => {
        const {
            parentId,
            ...others
        } = item;
        if (parentId !== undefined) {
            const parent = items.find(parent => parent.id === parentId);
            return {
                ...others,
                visible: parent['data-expanded']
            };
        }
        return item;
    });

    const userName = localStorage.getItem("Name");
    return <div className="main" >

        <div className="top-toolbar">
            <div
                style={{
                    display: "flex",
                    justifyContent:"space-around",
                }}
            >
                {/*<Button icon="menu" fillMode="flat" onClick={handleClick} />*/}
                <img src="../KRH-logo.png" onClick={event => window.location.href = '/'} />


                

                <div className="title" style={{
                    width: "80%",
                    textAlign: "center",
                }}><span className="krh-secondary">KRH QHSE</span> OUTSOURCED SERVICES PORTAL</div>
                
                <div className="UserNm">
                    Welcome {userName} <a onClick={signOut} target="_blank"><span className="k-icon k-i-logout k-icon-24" title="Sign Out"></span></a>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="main-content">
        {/*<Drawer expanded={drawerExpanded} mode='push' width={250} items={data} item={CustomItem} onSelect={onSelect}>*/}
        {/*    <DrawerContent>*/}
        {/*        <div style={{*/}
        {/*            position: "relative",*/}
        {/*        }}>*/}
        {/*            <ProtectedRoutes></ProtectedRoutes>*/}
        {/*        </div>*/}
        {/*    </DrawerContent>*/}
        {/*</Drawer>*/}
                <ProtectedRoutes></ProtectedRoutes>
            </div>
        </div>
        
    </div>;

};
