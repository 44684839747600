import { React, useRef, useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import { getItems } from "../../Api/handlers/transactions";
import { KendoDateTimeFormat } from "../../GenericCells/KendoDateTimeFormat";

const Index = () => {
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [data, setData] = useState([]);
    const [openCase, setOpenCase] = useState(0);
    const [closedCase, setClosedCase] = useState(0);
    const [totalCase, setTotalCase] = useState(0);

    const initialFilter = {
        logic: "and",
        filters: [
            {
                field: "statusName",
                operator: "contains",
                value: "Open",
            },
            {
                field: "step",
                operator: "contains",
                value: "Service Provider Investigation",
            },
        ],
    };
    const [filter, setFilter] = useState(initialFilter);

    //#region pagging
    const initialDataState = { skip: 0, take: 15, };
    const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(15);
    const pageChange = (event) => {
        //const targetEvent = event.targetEvent;
        const take = event.page.take;

        setPage({
            ...event.page,
            take,
        });
    }
    //#endregion




    function getData() {
        getItems('CCRBasic', { Option: "All" }, 'getAllData')
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (err) {
                setErrMsg('Exception ' + err);
            });
    }

    function getCounts() {
        getItems('QHSEDataSet', { Option: 'CCITotal' }, 'getRowByParams')
            .then(function (response) {
                
                    const openCaseTemp = parseInt(response.data.valCol1??0);
                    const closedCaseTemp = parseInt(response.data.valCol2??0);
                    const totalCaseTemp = openCaseTemp + closedCaseTemp;
                    setOpenCase(openCaseTemp);
                    setClosedCase(closedCaseTemp);
                    setTotalCase(totalCaseTemp);
                
            })
            .catch(function (err) {
                setErrMsg('Exception ' + err);
            });
    }

    useEffect(() => {
        getCounts();
        getData();
    }, [])

    const itemLink = (props) => {
        const id = props.dataItem.id;
        const field = props.field || "";
        return (
            <td style={{ fontWeight: 'bold', textAlign: 'center' }} >
                <a href={"/caterTeamInvestigation?id=" + id}>{props.dataItem[field]}</a>
            </td>
        );
    };

    const showItemInCentre = (props) => {
        const field = props.field || "";
        return (
            <td style={{ textAlign: 'center' }} >
                {props.dataItem[field]}
            </td>
        );
    };

    return (
        <>
            

            <div>
                <legend className={"k-form-legend"}>
                    Customer Complaints
                </legend>
                <div className="row">
                    <div className="card" style={{
                        backgroundColor: '#ef8b22'
                    }}>
                        <div>OPEN</div>
                        <div>{openCase}</div>
                    </div>

                    <div className="card" style={{
                        backgroundColor: 'green'
                    }}>
                        <div>CLOSED</div>
                        <div>{closedCase}</div>
                    </div>
                </div>
            </div>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <Grid
                data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={filterBy(data, filter).length}
                pageable={true}
                pageSizeValue={pageSizeValue}
                onPageChange={pageChange}
                filterable={true}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                resizable={true}
            >
                <GridColumn field="ccrno" title="CCR#" width="150px" cell={itemLink} />
                <GridColumn field="campName" title="Camp Name" cell={showItemInCentre} />
                <GridColumn field="complaintDate" title="Complaint Date" filter="date" cell={KendoDateTimeFormat} />
                {/*<GridColumn field="immidiateAction" title="Immidiate Action" cell={showItemInCentre} />*/}
                {/*<GridColumn field="complaintTypeName" title="Complaint Type" cell={showItemInCentre} />*/}
                <GridColumn field="complaintCategoryName" title="Complaint Category" cell={showItemInCentre} />

                <GridColumn field="step" title="Current Step" cell={showItemInCentre} className={data.step} />
                <GridColumn field="statusName" title="Status" cell={showItemInCentre} width="150px" />
                {/*<GridColumn field="documentId" title="Attachments" width={"100px"} cell={attachmentLink} filterable={false} />*/}
                    </Grid>
        </>
    );
};
export default Index;