import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const civilIdTrim = new RegExp(/^\d{7}$/);
const numberOnly = new RegExp(/^\d+$/);
const yearRegex = new RegExp(/^(19|20|21|22|23|24|25|26)\d{2}$/);
const civiIdRegex = new RegExp(/^(1|2|3)((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229)(\d{5})$/);
const selectDropBox = new RegExp(/^\+?(\d*[1-9]\d*\.?|\d*\.\d*[1-9]\d*)$/);
const passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,32})");

export const termsValidator = value => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = value => !value ? "Email field is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
export const nameValidator = value => !value ? "Name is required" : value.length < 7 ? "Name should be at least 7 characters long." : "";
export const nameArValidator = value => !value ? "Arabic Name is required" : value.length < 7 ? "Arabic Name should be at least 7 characters long." : "";
export const codeValidator = value => !value ? "Code is required" : value.length < 3 ? "Code should be at least 3 characters long." : "";
export const userNameValidator = value => !value ? "User Name is required" : value.length < 5 ? "User name should be at least 5 characters long." : "";
export const phoneValidator = value => !value ? "Phone number is required." : phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const cardValidator = value => !value ? "Credit card number is required. " : ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = value => !value ? "CVC code is required," : cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = value => !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = value => value ? "" : "Number of Nights is required.";
export const issueDateValidator = value => value ? "" : "Issue Date is required.";
export const expiryDateValidator = value => value ? "" : "Expiry Date is required.";
export const colorValidator = value => value ? "" : "Color is required.";
export const requiredValidator = value => value ? "" : "Error: This field is required.";
//export const passwordValidator = value => value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = value => value ? "" : "Address is required.";
export const yearValidator = value => !value ? "Year is required." : yearRegex.test(value) ? "" : "Not a valid Year.";
export const civiIdValidator = value => !value ? "Civil Id is required. " : civiIdRegex.test(value) ? "" : "Not a valid civil id number.";
export const civiIdTrimValidator = value => !value ? "Civil Id is required. " : civilIdTrim.test(value) ? "" : "Not a valid civil id number.";
export const numberOnlyValidator = value => !value ? "Number is required. " : numberOnly.test(value) ? "" : "Not a valid number.";
export const DateValidator = value => value ? "" : "Date is required.";
export const DropboxValidator = value => value ? selectDropBox.test(value) : "Selection is required.";

export const rootcauseValidator = (value) => (!value ? "Root Cause is required" : "");
export const preventiveActionValidator = (value) => (!value ? "Preventive Action is required" : "");
export const corrrectiveActionValidator = (value) => (!value ? "Corrrective Action is required" : "");
export const passwordValidator = value => !value ? "Password is required." : passwordRegex.test(value) ? "" : "Password doesn't meet complexity requirements";

const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = values => {
    const userName = userNameGetter(values);
    const emailValue = emailGetter(values);
    if (userName && emailValue && emailRegex.test(emailValue)) {
        return {};
    }
    return {
        VALIDATION_SUMMARY: 'Please fill in the following fields.',
        ['username']: !userName ? 'User Name is required.' : '',
        ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
    };
};