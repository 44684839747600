import React from 'react';
import '@progress/kendo-theme-fluent/dist/all.css';
import './index.css';
import Login from './pages/Startup/Login';
import HomePage from './pages/Homepage/home';
import CaterTeamInvestigation from './pages/CateringTeam/setup';
import CaterTeamInvestigationIndex from './pages/CateringTeam/Index';
import ChangePasswordIndex from './pages/ChangePassword/Index';

import { Routes, Route, Navigate } from 'react-router-dom';
import { DrawerContainer } from './pages/Navbar/NavContainer';
import "./pages/Navbar/Navbar.css";

const NavbarLayout = () => (
    <>
        <DrawerContainer />
        <footer>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                <div style={{
                    textAlign: "left",
                }}>
            &#169; All Rights Reserved by &#169; 2023 IT Development BIG Holding | <a href="https://www.krhkw.com/" target="_blank" rel="noreferrer">Kuwait Resources House</a>
                </div>
                <div style={{
                    width: "72%",
                    textAlign: "right",
                }}>
            <img style={{
            width: "25px", 
                    }} src="../ResetPassword.png" onClick={event => window.location.href = '/changePassword'} alt="Reset Password" />
                </div>
        </div>
        </footer>
    </>
);

const App = () => {
    return (
        <>
            <Routes>
                <Route element={<NavbarLayout />}>
                    <Route path="/" element={<HomePage />} />
                    <Route exact path="/caterTeamInvestigation/all" element={<CaterTeamInvestigationIndex />} />
                    <Route exact path="/caterTeamInvestigation" element={<CaterTeamInvestigation />} />
                    <Route exact path="/changePassword" element={<ChangePasswordIndex />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
};

export default App;
