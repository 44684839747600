import { React, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import './../Startup/Login.css';
import { authenticateUser } from "../../Api/handlers/Account";
import { setAuthToken } from "../../Api/index";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [navigateTo, setNavigateTo] = useState('/');

    useEffect(() => {
        setValidName(user);
    }, [user])

    useEffect(() => {
        setValidPwd(pwd);
    }, [pwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    useEffect(() => {
        if (location.state != null)
            setNavigateTo(location.state.from);
    }, [location.state])


    const handleSubmit = async (e) => {
        e.preventDefault();

        authenticateUser(user, pwd, true)
            .then(function (response) {
                setUser('');
                setPwd('');
                const token = response.data;
                setAuthToken(token);
                setSuccess(true);
                navigate(navigateTo);
            })
            .catch(function (err) {
                setSuccess(false);
                if (!err?.response) {
                    setErrMsg('No Server Response ' + err);
                } else if (err.response?.status === 409) {
                    setErrMsg('Username Taken');
                } else {
                    setErrMsg('Invaild UserName and/or Password')
                }
                errRef.current.focus();
            });
    }

    return (
        <>
            {success ? <div>Successfully Logged In</div> : (
                <div className="loginContent">
                    <div className="login-outer">
                        <div style={{ display: "flex", margin: "0px", flexWrap: "wrap"}}>
                            <div className="krh-slogen krh-secondary-bg" style={{width: "5%", }}>
                                <p>Your value driven partner of choice</p>
                            </div>                    

                            <div className="login-container" style={{ display: "flex", paddingTop: "15px", flexWrap: "wrap" }}>
                                <h1>
                                    <span className="krh-secondary">KRH QHSE</span>
                                    &nbsp; Outsourced Services Login
                                </h1>

                                <div className="col-11">
                                    <section>
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                        <form onSubmit={handleSubmit}>
                                            <div style={{ display: "flex", margin: "20px", flexWrap: "wrap" }}>
                                                <div className="col-9" >
                                                    <div className="field">
                                                            <input
                                                                type="text"
                                                                id="user"
                                                                ref={userRef}
                                                                autoComplete="off"
                                                                onChange={(e) => setUser(e.target.value)}
                                                                value={user}
                                                                required    
                                                                placeholder="User Name"
                                                                aria-invalid={validName ? "false" : "true"}
                                                                aria-describedby="uidnote"
                                                                className="loginInput"
                                                            />

                                                        <label className="UserName" style={{ paddingBottom: "10px" }}>UserName</label>
                                                </div>
                                                <div className="field">
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                onChange={(e) => setPwd(e.target.value)}
                                                                value={pwd}
                                                                required
                                                                placeholder="Password"
                                                                aria-invalid={validPwd ? "false" : "true"}
                                                                aria-describedby="pwdnote"
                                                                className="loginInput"
                                                            />
                                                        <label className="Password" style={{ paddingBottom: "10px" }}>Password</label>
                                                </div>
                                            </div>
                                                <div className="col-3"
                                                    style={{ textAlign: "left", padding: "10px 0px 0px," }}>
                                                    <button className="LoginBtn" ></button>
                                                </div>
                                            </div>
                                        </form>
                                    </section>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Login