// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import {
    BlobServiceClient} from "@azure/storage-blob";
import { format } from 'react-string-format';

// <snippet_createBlobInContainer>
const createBlobInContainer = async (file, id, sas) => {

    const connStr = `https://${process.env.REACT_APP_AZURE_ACCOUNT_NAME}.blob.core.windows.net${sas}`;
    const blobServiceClient = new BlobServiceClient(connStr);
    const newcontainerClient=blobServiceClient.getContainerClient(process.env.REACT_APP_CONTAINER_NAME);

    let fileTypeTemp = file.type;

    if (fileTypeTemp.startsWith('image'))
        fileTypeTemp = 'Image';
    else if (fileTypeTemp.startsWith('video'))
        fileTypeTemp = 'Video';
    else
        fileTypeTemp = 'Other';

    const tags = {
        FileType: fileTypeTemp,
        Object: process.env.REACT_APP_OBJECT_NAME,
        ObjectId: id,
        Solution: process.env.REACT_APP_AZURE_SOLUTION
    };

    const blobName = format('{0}-STEP3-{1}', id, file.name);

    const blockBlobClient = newcontainerClient.getBlockBlobClient(blobName);

    const options = {
        blobHTTPHeaders: { ContentType: file.ContentType }, tags: tags
    };

    // upload file
    // await blockBlobClient.setTags(tags);
    await blockBlobClient.uploadData(file, options);
};

// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file, id,sas) => {
    if (!file) return;

    // upload file
    await createBlobInContainer(file, id,sas);
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;