import { React, useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { FormTextArea } from '../../Components/form-components';
import { getItem, saveItem, getItems, getItemNoParam } from "../../Api/handlers/transactions";
import { format } from "date-fns";
import { KendoDateTimeFormat } from "../../GenericCells/KendoDateTimeFormat";
import uploadFileToBlob from '../../AzureStorageBlob/azure-storage-blob';

const CaterTeamInvestigation = () => {

    const userName = localStorage.getItem("Name");
    const errRef = useRef();
    const succRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [id, setId] = useState(0);

    const initformFields = {
        id: 0,
        complaintDate: new Date(),
        otherCategory: "",
        projectName: "",
        empControlNo: 'undefined',
        createdByName: "",
        createdOn: new Date(),
        immidiateAction: "",
        description: "",
        ccrno: 'undefined',
        ccrInvId: 'undefined',
        stepId: 'undefined',
        complaintTypeName: "",
        locationName: "",
        employeeName: "",
        empContactNo: 'undefined',
        campName: "",
        investigatedOn: new Date(),
        investigatedByName: "",
        complaintCategoryName: "",
        employeeTypeName: "",
        empName: "",
        rootCause: "",
        preventiveAction: "",
        corrrectiveAction: "",
        campId: 0,
        employeeTypeId: 0,
        complaintSourceId: 1,
        complaintTypeId: 0,
        complaintCategoryId: 0,
        locationId: 0,
        statusId: 0,
        active: 0,
        qhseRemarks: "",
        qhseVerifiedByName: "",
        qhseVerifiedOn: new Date(),
    }

    const initHistroyFormFields = {
        id: 0,
        CCRId: 0,
        investigatingOn: new Date(),
        investigationBy: new Date()
    }

    const [blobList, setBlobList] = useState([]);

    // current file to upload into container
    const [fileSelected, setFileSelected] = useState();
    const [fileUploaded, setFileUploaded] = useState('');
    const [uploading, setUploading] = useState(false);

    const [formFields, setFormFields] = useState(initformFields);
    const [searchParams, setSearchParams] = useSearchParams();

    const LoadingCateringData = "Customer Complaint Investigation Data Loading!";
    const LoadedCateringData = "Customer Complaint Investigation";
    const [title, setTitle] = useState(LoadingCateringData);
    const [isLoaded, setDataLoaded] = useState(false);

    const [dataHistroy, setDataHistroy] = useState([initHistroyFormFields]);

    const [sas, setSas] = useState('');
    const [step, setStep] = useState('');
    const [cCRInvCount, setCCRInvCount] = useState(0);
    const [cTitle, setCTitle] = useState('');
    const [qhseStatus, setQhseStatus] = useState('');  


    useEffect(() => {
        if (succMsg != '')
            setErrMsg('');
    }, [succMsg])

    useEffect(() => {
        if (isLoaded)
            setTitle(LoadedCateringData);
        else
            setTitle(LoadingCateringData);

    }, [isLoaded])

    useEffect(() => {
        if (errMsg != '')
            setSuccMsg('');
    }, [errMsg])

    useEffect(() => {
        if (id != 0 && id != formFields.id) {
            setTitle(LoadedCateringData);
            formFields.id = id;
            getData();

            getAzureDataWithId()
        }
        setSearchParams({ id: id });
    }, [id])

    useEffect(() => {
        if (searchParams.get("id") != null) {
            setId(searchParams.get("id"));
        }
        getSas();
    }, [])

    useEffect(() => {
        if (cCRInvCount == 0) {
            setDataHistroy([]);
            setCTitle("Service Provider Investigation");
            setQhseStatus(``);
        } else {
            if (step != "Completed" && formFields.stepId ===17) {
                setCTitle("Service Provider Re-Investigation");
                setQhseStatus(`Rejection`);
            }
        }
    }, [cCRInvCount, formFields.stepId])   

   

    //#region Get Data    

    //Get Item Data
    function getData() {
        getItem('CCRBasic', id, 'getDataWithId')            
            .then(function (response) {
                const responseTemp = response.data;
                var complaintDate = new Date(responseTemp.complaintDate);
                var createdOn = new Date(responseTemp.createdOn);
                var investigatedOn = new Date(responseTemp.investigatedOn);
                var qhseVerifiedOn = new Date(responseTemp.qhseVerifiedOn);

                if (responseTemp.id > 0) {
                    setFormFields({
                        id: formFields.id = responseTemp.id,
                        complaintDate: complaintDate,
                        otherCategory: responseTemp.otherCategory,
                        projectName: responseTemp.projectName,
                        empControlNo: responseTemp.empControlNo,
                        createdByName: responseTemp.createdByName,
                        createdOn: createdOn,
                        immidiateAction: responseTemp.immidiateAction,
                        description: responseTemp.description,
                        ccrno: responseTemp.ccrno,
                        complaintTypeName: responseTemp.complaintTypeName,
                        locationName: responseTemp.locationName,
                        employeeName: responseTemp.employeeName,
                        empContactNo: responseTemp.empContactNo,
                        campName: responseTemp.campName,
                        complaintCategoryName: responseTemp.complaintCategoryName,
                        employeeTypeName: responseTemp.employeeTypeName,
                        empName: responseTemp.empName,
                        rootCause: responseTemp.rootCause??"",
                        preventiveAction: responseTemp.preventiveAction??"",
                        corrrectiveAction: responseTemp.corrrectiveAction??"",
                        campId: responseTemp.campId,
                        employeeTypeId: responseTemp.employeeTypeId,
                        complaintSourceId: responseTemp.complaintSourceId,
                        complaintTypeId: responseTemp.complaintTypeId,
                        complaintCategoryId: responseTemp.complaintCategoryId,
                        locationId: responseTemp.locationId,
                        statusId: responseTemp.statusId,
                        active: responseTemp.active,
                        ccrInvId: responseTemp.ccrInvId,
                        stepId: responseTemp.stepId,
                        investigatedOn: investigatedOn,
                        investigatedByName: responseTemp.investigatedByName,
                        qhseRemarks: responseTemp.qhseRemarks,
                        qhseVerifiedByName: responseTemp.qhseVerifiedByName,
                        qhseVerifiedOn: qhseVerifiedOn
                    });
                    setStep(responseTemp.step);
                    setCCRInvCount(parseInt(responseTemp.ccrInvCount));
                    if (parseInt(responseTemp.ccrInvCount) > 0) {
                        getDataHistory();
                    }
                    setDataLoaded(true);
                }
            })
            .catch(function (err) {
                setErrMsg('No Server Response ' + err);
            });
    }

    //Customer Complaint Investigation History
    function getDataHistory() {
        getItems('CCRInv', { Option: "All", CCRId: id }, 'getAllData')
            .then(function (response) {
                const responseTemp = response.data;
                setDataHistroy(responseTemp);
            })
            .catch(function (err) {
                setErrMsg('No Server Response ' + err);
            });
    }

    //#endregion

    //#region Save Data
    const setData = dataItem => {
        setId(dataItem.id ?? 0);
        const complaint =
        {
            id: formFields.id = dataItem.id,
            complaintDate: dataItem.complaintDate,
            otherCategory: dataItem.otherCategory,
            projectName: dataItem.projectName,
            empControlNo: dataItem.empControlNo,
            createdByName: dataItem.createdByName,
            createdOn: dataItem.createdOn,
            immidiateAction: dataItem.immidiateAction,
            description: dataItem.description,
            ccrno: dataItem.ccrno,
            complaintTypeName: dataItem.complaintTypeName,
            locationName: dataItem.locationName,
            employeeName: dataItem.employeeName,
            empContactNo: dataItem.empContactNo,
            campName: dataItem.campName,
            complaintCategoryName: dataItem.complaintCategoryName,
            employeeTypeName: dataItem.employeeTypeName,
            empName: dataItem.empName,
            rootCause: dataItem.rootCause,
            preventiveAction: dataItem.preventiveAction,
            corrrectiveAction: dataItem.corrrectiveAction,
            campId: dataItem.campId,
            employeeTypeId: dataItem.employeeTypeId,
            complaintSourceId: dataItem.complaintSourceId,
            complaintTypeId: dataItem.complaintTypeId,
            complaintCategoryId: dataItem.complaintCategoryId,
            locationId: dataItem.locationId,
            statusId: dataItem.statusId,
            active: dataItem.active,
            ccrInvId: 0,
            stepId:17
        }

        saveItem('CCRBasic', id, complaint, 'saveDataWithId')
            .then(function (response) {
                setSuccMsg('Data saved successfully. Your investigation details have been sent to the next level of the workflow.');
                var qhseVerifiedOn = new Date();
                setFormFields(formData => ({
                    ...formData,
                    investigatedOn: qhseVerifiedOn,
                    investigatedByName: userName,
                    rootCause: dataItem.rootCause,
                    preventiveAction: dataItem.preventiveAction,
                    corrrectiveAction: dataItem.corrrectiveAction,
                    stepId: 18
                }));

                getDataHistory();
                onFileUpload();
            })
            .catch(function (err) {
                setErrMsg('Exception ' + err);
            });
    }
    //#endregion

    const LinkedItem = (props) => {
        const field = props.field || "";

        return (
            <td style={{ textAlign: 'center', fontWeight: 'bold' }} >
                <div onClick={() => OnClickLinkedItem(props.dataItem.ccrId, props.dataItem.id)}>
                    {props.dataItem[field]}
                </div>
            </td>
        );
    };

    const OnClickLinkedItem = (ccrNo, id) => {
        getItems('CCRBasic', { Option: 'Single', Id: ccrNo, CCRInvId: id }, 'getAllDataByParms')           
            .then(function (response) {
                const responseTemp = response.data;
                var investigatedOn = new Date(responseTemp.investigatedOn);
                var qhseVerifiedOn = new Date(responseTemp.qhseVerifiedOn);

                if (responseTemp.id > 0) {
                    setFormFields(formData => ({
                        ...formData,
                        investigatedOn: investigatedOn,
                        investigatedByName: responseTemp.investigatedByName,
                        rootCause: responseTemp.rootCause,
                        preventiveAction: responseTemp.preventiveAction,
                        corrrectiveAction: responseTemp.corrrectiveAction,
                        qhseRemarks: responseTemp.qhseRemarks,
                        qhseVerifiedByName: responseTemp.qhseVerifiedByName,
                        qhseVerifiedOn: qhseVerifiedOn
                    }));
                    setCCRInvCount(parseInt(responseTemp.ccrInvCount));
                }
            })
            .catch(function (err) {
                setErrMsg('Exception ' + err);
            });
    };

    //#region Upload File Related Actions
    function getSas() {
        getItemNoParam('azure', 'getAllData')
            .then(function (response) {
                setSas(response.data);
            })
            .catch(function (err) {
                setErrMsg('No Server Response ' + err);
            });
    }
    function getAzureDataWithId() {

        getItems('Azure', { Id: id, Obj: process.env.REACT_APP_OBJECT_NAME, Container: process.env.REACT_APP_CONTAINER_NAME }, 'getAllData')
            .then(function (response) {
                GetUploadedImages(response.data);
            })
            .catch(function (err) {
                setErrMsg('No Server Response ' + err);
            });
    }

    const onFileChange = (event) => {
        // capture file into state
        setFileSelected(event.target.files[0]);
    };

    const onFileUpload = async () => {

        if (fileSelected && fileSelected?.name) {
            // prepare UI
            setUploading(true);

            // *** UPLOAD TO AZURE STORAGE ***
            await uploadFileToBlob(fileSelected, formFields.id.toString(), sas);

            // reset state/form
            await getAzureDataWithId();
            setFileSelected(null);
            setFileUploaded(fileSelected.name);
            setUploading(false);
        }
    };

    const GetUploadedImages = (data) => {
        const returnedBlob = [];
        var i = 1;
        for (const blob of data) {
            const blobItem = {
                id: blob.id,
                name: blob.fileName,
                url: blob.storageUrl,
                type: blob.fileType
            }
            returnedBlob.push(blobItem);
        }
        setBlobList(returnedBlob);
    };

    const DisplayUpload = () => (
        <div className="row" style={{ margin: "10px 0px" }}>
            <input type="file" onChange={onFileChange} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-upload-button" />
            <button type="submit" onClick={onFileUpload}>
                Upload!
            </button>
        </div>
    );

    const DisplayFiles = blobList.map((blob, i) => {
        return (

            <div key={"ufile" + i}>
                <span className={"k-icon k-i-file fileicons"}></span>
                <span className="k-file-name-size-wrapper">
                    <span className="k-file-name" title={blob.name}><a href={blob.url} target="_blank" rel="noreferrer">{blob.name}</a></span>
                </span>
            </div>

        );
    });

    const onChangeFormControls = (e) => {
        if (e.target.name == "rootCause")
            setFormFields(formData => ({
                ...formData,                
                rootCause: e.value                
            }));
        else if (e.target.name == "preventiveAction")
            setFormFields(formData => ({
                ...formData,
                preventiveAction: e.value
            }));
        else if (e.target.name == "corrrectiveAction")
            setFormFields(formData => ({
                ...formData,
                corrrectiveAction: e.value
            }));
            
    };
    const inputRef = useRef();
    //#endregion

    return (
        <>
            
            <legend className={"k-form-legend"}>
            </legend>

            {isLoaded &&
                <>
                <div className="row">
                        <div className="StepBox col-12">
                            CURRENT STEP : {step}
                        </div>
                    <div className={"StepBoxDetail col-12 " + step}>
                            <div className="col-2"><div className="lbl">Id</div><div>{formFields.id}</div></div>
                            <div className="col-2"><div className="lbl">CCR#</div><div>{formFields.ccrno}</div></div>
                            <div className="col-2"><div className="lbl">Complaint Date</div><div>{format(formFields.complaintDate, 'dd/MMM/yyyy')}</div></div>
                            <div className="col-2"><div className="lbl">Camp</div><div>{formFields.campName}</div></div>
                            <div className="col-2"><div className="lbl">status</div><div>{formFields.status}</div></div>                            
                        </div>
                    </div>
                    <div style={{
                        float: 'left',
                        width: '74%'
                    }}>
                    <PanelBar expandMode="multiple">
                            <PanelBarItem expanded={true} title="STEP 1: Complaint/ Concern Information">
                                <div style={{
                                    padding: "10px",
                                    fontSize: '12px'
                                }}>
                                    <h3>Basic Information</h3>
                                    <div className="row">
                                        <div className="col-3 lbl">Project</div><div className="col-3"> {formFields.projectName}&nbsp; </div>
                                        <div className="col-3 lbl">Complaint Type</div><div className="col-3">{formFields.complaintTypeName}&nbsp;</div>
                                        <div className="col-3 lbl">Complaint Category</div><div className="col-3">{formFields.complaintCategoryName}&nbsp; </div>
                                        <div className="col-3 lbl">Category (Other)</div><div className="col-3"> {(formFields.otherCategory == null ? '-' : formFields.otherCategory)} &nbsp; </div>

                                        <div className="col-3 lbl">Location</div><div className="col-9">{formFields.locationName}&nbsp; </div>
                                        <div className="col-3 lbl">Complaint Description</div><div className="col-9"> {formFields.description} </div>
                                        <div className="col-3 lbl">Immediate Action</div><div className="col-9"> {formFields.immidiateAction}</div>
                                    </div>
                                    <h3>Employee Information</h3>
                                    <div className="row">
                                        <div className="col-3 lbl">Employee Contact #</div><div className="col-3">{(formFields.empContactNo == null ? '-' : formFields.empContactNo)}&nbsp; </div>
                                        <div className="col-3 lbl">Employee</div><div className="col-3">{formFields.employeeName}&nbsp;</div>
                                        <div className="col-3 lbl">Employee Type</div><div className="col-3">{formFields.employeeTypeName}&nbsp; </div>
                                        <div className="col-3 lbl">Employee Name (Other)</div><div className="col-3">{(formFields.empName == null ? '-' : formFields.empName)}&nbsp; </div>
                                        <div className="col-3 lbl">Control# (Other)</div><div className="col-3"> {(formFields.empControlNo == null ? '-' : formFields.empControlNo)}&nbsp; </div>
                                    </div>
                                    <div className="row highlighted">
                                        <div className="col-3 lbl">Created By</div><div className="col-3"> {formFields.createdByName} </div>
                                        <div className="col-3 lbl">Created On</div><div className="col-3"> {format(formFields.createdOn, 'dd/MMM/yyyy')} </div>
                                    </div>
                                </div>
                            </PanelBarItem>

                            <PanelBarItem expanded={true} title={"STEP 2: " + cTitle}>
                                {(formFields.stepId == 17) ?
                                    (<div>
                                        <Form
                                            onSubmit={setData} initialValues={formFields}
                                            key={JSON.stringify(formFields)}
                                            render={(formRenderProps) => (
                                                <FormElement
                                                    style={{
                                                        margin: 0,
                                                        padding: 10
                                                    }}
                                                >

                                                    <fieldset className={"k-form-fieldset"}>
                                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                                        <p ref={succRef} className={succMsg ? "succmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
                                                        <div>
                                                            <Field
                                                                id={"rootCause"}
                                                                name={"rootCause"}
                                                                label={"Root Cause"}
                                                                component={FormTextArea}
                                                                placeholder="Enter Root Cause..."
                                                                required={true} 
                                                            />

                                                            <Field
                                                                id={"preventiveAction"}
                                                                name={"preventiveAction"}
                                                                label={"Preventive Action"}
                                                                component={FormTextArea}
                                                                placeholder="Enter Preventive Action..."
                                                                required={true}
                                                            />
                                                            <Field
                                                                id={"corrrectiveAction"}
                                                                name={"corrrectiveAction"}
                                                                label={"Corrrective Action"}
                                                                component={FormTextArea}
                                                                placeholder="Enter Corrrective Action..."
                                                                required={true}
                                                            />
                                                        </div>
                                                        

                                                        <span className={"k-form-separator"} />


                                                        {(!uploading ) && DisplayUpload()}
                                                        {uploading && <div>Uploading</div>}

                                                        <span className={"k-form-separator"} />


                                                        <div className="k-form-buttons">
                                                            <Button
                                                                themeColor={"primary"}
                                                                type={"submit"}
                                                                disabled={!formRenderProps.allowSubmit}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </fieldset>
                                                </FormElement>
                                            )}
                                        />
                                    </div>) : (

                                        <div className="row" style={{
                                            padding: "10px",
                                            fontSize: '12px'
                                    }}>
                                        <div className="col-12">
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                            <p ref={succRef} className={succMsg ? "succmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
                                        </div>
                                            <div className="row">
                                                <div className="col-3 lbl">Root Cause</div><div className="col-9">{formFields.rootCause}&nbsp;</div>
                                                <div className="col-3 lbl">Preventive Action</div><div className="col-9">{formFields.preventiveAction}&nbsp;</div>
                                                <div className="col-3 lbl">Corrective Action</div><div className="col-9">{formFields.corrrectiveAction}&nbsp;</div>
                                            </div>
                                            <div className="row highlighted">
                                                <div className="col-3 lbl">Investigated By</div><div className="col-3">{formFields.investigatedByName}&nbsp;</div>
                                                <div className="col-3 lbl">Investigated On</div><div className="col-3">{format(formFields.investigatedOn, 'dd/MMM/yyyy')}&nbsp;</div>
                                            </div>
                                        </div>

                                    )
                                }
                            </PanelBarItem>


                            {formFields.qhseRemarks != null ?
                                (
                                <PanelBarItem expanded={true} title={`STEP 3: QHSE Verification ` + qhseStatus} className={qhseStatus} >
                                    <div style={{
                                        padding: "10px",
                                        fontSize: '12px'
                                    }}>
                                        <div className="row">

                                            <div className="col-3 lbl">Remarks </div><div className="col-9">{formFields.qhseRemarks}&nbsp; </div>
                                        </div>
                                        <div className="row highlighted">
                                            <div className="col-3 lbl">Verified By</div><div className="col-3"> {formFields.qhseVerifiedByName} </div>
                                            <div className="col-3 lbl">Verified On</div><div className="col-3"> {format(formFields.qhseVerifiedOn, 'dd/MMM/yyyy')} </div>
                                        </div>
                                    </div>
                                </PanelBarItem>
                            )
                            : (
                                <PanelBarItem disabled={true}  title={`STEP 3: QHSE Verification`} className={qhseStatus} ></PanelBarItem>
                              )
                            }
                        </PanelBar>



                    </div>

                    <div style={{
                        float: 'left',
                        paddingLeft: '1%',
                        width: '25%'
                    }}>

                        <div className={"panelBar"}>
                            INVESTIGATION HISTROY
                        </div>

                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                        <Grid
                            data={dataHistroy}
                            resizable={true}
                        >
                            <GridColumn field="id" title="Id" cell={LinkedItem} width="50" />
                            <GridColumn field="investigatedOn" title="Investigated On" filter="date" cell={KendoDateTimeFormat} />
                            <GridColumn field="qhseVerifiedOn" title="Verified On" filter="date" cell={KendoDateTimeFormat} />
                        </Grid>

                        <div className={"panelBar"}>SUPPORTING DOCUMENTS</div>
                        <div className="row">

                            <div className="wbox" >
                                {DisplayFiles}
                            </div>


                            <span className={"k-form-separator"} />


                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default CaterTeamInvestigation;
